<template>
	<b-container fluid class="m-0 p-0">
		<Breadcrumb titulo="Discador Automático" :items="breadcrumb" />
		<b-container fluid class="discadores-container">
			<b-row align-h="between">
				<b-col cols="12" md="auto">
					<input type="date" v-model="dataInicio" />
					<input type="date" v-model="dataFim" />
					<input type="text" v-model="consulta" placeholder="Buscar" />
					<select v-model="status">
						<option :value="null">Status</option>
						<option v-for="status in statusList" :value="status.Value">
							{{status.Label}}
						</option>
					</select>
					<select v-model="setor">
						<option :value="null">Setor</option>
						<option v-for="setor in setores" :value="setor.Id">
							{{setor.Nome}}
						</option>
					</select>
					<b-button class="box-cliente rounded-0" @click="consultarDiscadores">Filtrar</b-button>
				</b-col>
				<b-col cols="12" md="auto">
					<b-button class="box-cliente rounded-0" @click="abrirModalNovoDiscador">
						<i class="fas fa-plus mr-1"></i>
						Novo discador
					</b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="discadores-list-contatiner">
					<b-table :fields="fields" :items="discadores" striped hover>
						<template #cell(Inclusao)="scope">
							{{moment(scope.value).format("DD/MM/YYYY / HH:mm:ss")}}
						</template>
						<template #cell(Totais)="scope">
							<div>
								<b class="text-cliente">{{scope.item.QuantidadeContatos}} contatos</b>
							</div>
							<div>
								<b>Chamadas:</b>
								{{scope.item.QuantidadeChamadas}}
							</div>
							<div>
								<b>Na Fila:</b>
								{{scope.item.QuantidadeNaFila}}
							</div>
							<div>
								<b>Chamadas Atendidas:</b>
								{{scope.item.QuantidadeChamadaAtendida}}
							</div>
							<div>
								<b>Chamadas Rejeitadas:</b>
								{{scope.item.QuantidadeChamadaRejeitada}}
							</div>
							<div>
								<b>Erro:</b>
								{{scope.item.QuantidadeErro}}
							</div>
						</template>
						<template #cell(StatusDescription)="scope">
							<div v-if="['Ativo','Interrompido', 'Processando...'].includes(scope.value)" class="data-field insider-icon">
								<b-spinner v-if="scope.value == 'Processando...'" small></b-spinner>
								<i v-else
								   :class="`${statusIcons[scope.value]}`"
								   @click="alterarStatusDiscador(scope.item)"></i>
								<select v-model="scope.item.Status" @change="$event.preventDefault(); alterarStatusDiscador(scope.item, $event.target.value)">
									<option value="0">Ativo</option>
									<option value="1">Interrompido</option>
									<option value="2">Arquivado</option>
								</select>
							</div>
							<div v-else>
								<i :class="`${statusIcons[scope.value]} mr-2`"></i>
								{{scope.value}}
							</div>
						</template>
					</b-table>
					<center v-if="discadores && discadores.length == 0" class="text-secondary font-weight-bold">
						Nenhum discador encontrado para os filtros informados.
					</center>
					<center v-else-if="!discadores" class="text-secondary font-weight-bold">
						<b-spinner small class="mr-2"></b-spinner>
						Processando solicitação...
					</center>
				</b-col>
			</b-row>
		</b-container>
		<b-modal id="criar-discador"
				 title="Criar novo discador automático"
				 size="lg"
				 @hide="fecharModalNovoDiscador">
			<b-container>
				<b-row>
					<b-col class="data-field">
						<label for="criar-discador-identificacao">Identificação</label>
						<input type="text"
							   id="criar-discador-identificacao"
							   v-model="novoDiscador.Identificacao" />
					</b-col>
				</b-row>
				<b-row>
					<b-col class="data-field">
						<label for="criar-discador-setor">Setor de atendimento</label>
						<select id="criar-discador-setor"
								v-model="novoDiscador.SetorId"
								:disabled="!setoresValidos || setoresValidos.length == 0">
							<option v-if="!setoresValidos" :value="null">Carregando...</option>
							<option v-else-if="setoresValidos.length == 0" :value="null">...</option>
							<option v-for="setor in (setoresValidos ?? [])" :value="setor.Id">{{setor.Nome}}</option>
						</select>
					</b-col>
					<b-col class="data-field">
						<label for="criar-discador-canal">Canal de atendimento</label>
						<select id="criar-discador-canal"
								v-model="novoDiscador.CanalId"
								:disabled="!canaisValidos || canaisValidos.length == 0">
							<option v-if="!canaisValidos" :value="null">Carregando...</option>
							<option v-else-if="canaisValidos.length == 0" :value="null">...</option>
							<option v-for="canal in (canaisValidos ?? [])" :value="canal.Id">
								{{canal.Nome}} ({{canal.QuantidadeUsuarios}} usuário{{canal.QuantidadeUsuarios > 1 ? "s" : ""}})
							</option>
						</select>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="data-field">
						<label for="criar-discador-tentativas">Quantidade de tentativas por contato</label>
						<input type="number" id="criar-discador-tentativas" v-model="novoDiscador.TentativasPorContato" />
					</b-col>
					<b-col class="data-field">
						<label for="criar-discador-status">Iniciar agora</label>
						<select type="number" id="criar-discador-status" v-model="novoDiscador.Status">
							<option :value="0">Sim</option>
							<option :value="1">Não</option>
						</select>
					</b-col>
				</b-row>
				<b-row align-h="between">
					<b-col class="data-field">
						<label>Contatos para discagem</label>
						<div class="mb-2">
							{{novoDiscador.Contatos && novoDiscador.Contatos.length > 0 ? novoDiscador.Contatos.length : 0}}
							contatos selecionados
						</div>
						<b-button key="novoDiscadorAdicionarContatos"
								  v-if="!novoDiscador.Contatos"
								  class="box-cliente rounded-0"
								  @click="selecionarContatos">
							<i class="fas fa-plus mr-1"></i>
							Adicionar contatos
						</b-button>
						<b-button v-else
								  key="novoDiscadorLimparContatos"
								  class="box-cliente rounded-0"
								  @click="novoDiscador.Contatos = null ">
							<i class="fas fa-times mr-1"></i>
							Limpar lista de contatos
						</b-button>
					</b-col>
				</b-row>
			</b-container>
			<template #modal-footer="{ cancel }">
				<b-button variant="light"
						  @click="cancel()"
						  class="rounded-0"
						  :disabled="loaders.criandoDiscador">Cancelar</b-button>
				<b-button variant="success"
						  @click="criarNovoDiscador"
						  class="rounded-0"
						  :disabled="!novoDiscadorValido || loaders.criandoDiscador">
					<span v-if="loaders.criandoDiscador">
						<b-spinner small class="mr-1"></b-spinner>
						Processando...
					</span>
					<span v-else>Confirmar</span>
				</b-button>
			</template>
		</b-modal>
		<ModalSelecaoDestinatarios :mandatoryFields="['Telefone']" ref="modal-selecao-contatos" />
	</b-container>
</template>

<script>
	import axios from "axios";
	import moment from "moment";
	import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
	import ModalSelecaoDestinatarios from "@/components/campanhas/ModalSelecaoDestinatarios";

	export default {
		name: "DiscadorAutomaticoPage",
		components: {
			Breadcrumb,
			ModalSelecaoDestinatarios
		},
		data() {
			return {
				breadcrumb: [
					{
						id: "1",
						classe: "fa fa-bullhorn",
						texto: "Campanhas",
						link: "/campanhas"
					},
					{
						id: "2",
						classe: "fas fa-fax",
						texto: "Discador Automático",
						link: ""
					}
				],
				dataInicio: null,
				dataFim: null,
				consulta: null,
				status: null,
				statusList: null,
				setor: null,
				setores: [],
				setoresValidos: null,
				canaisValidos: [],
				fields: [
					{ key: "Identificacao", label: "Identificação", sortable: true },
					{ key: "SetorNome", label: "Setor de Atendimento", sortable: true },
					{ key: "CanalNome", label: "Canal de Atendimento", sortable: true },
					{ key: "UsuarioNome", label: "Usuário", sortable: true },
					{ key: "TentativasPorContato", label: "Tentativas por Contato" },
					{ key: "Totais", label: "Totais" },
					{ key: "Inclusao", label: "Data de Inclusão", sortable: true, class: "text-nowrap" },
					{ key: "StatusDescription", label: "Status", sortable: true, class: "text-nowrap" }
				],
				discadores: [],
				loaders: {
					criandoDiscador: false
				},
				novoDiscador: {
					Identificacao: null,
					TentativasPorContato: 1,
					SetorId: null,
					CanalId: null,
					Status: 0,
					Contatos: null
				},
				statusIcons: {
					Ativo: "fas fa-power-off text-success",
					Interrompido: "fas fa-power-off text-danger",
					Arquivado: "fas fa-archive"
				}
			};
		},
		watch: {
			"novoDiscador.SetorId": {
				handler() {
					if (this.novoDiscador.SetorId == null) return;
					this.novoDiscador.CanalId = null;
					this.canaisValidos = null;
					axios.get(`api/discador/setor/${this.novoDiscador.SetorId}/canais-validos`).then(response => this.canaisValidos = response.data ?? []);
				}
			}
		},
		computed: {
			novoDiscadorValido() {
				return this.novoDiscador.TentativasPorContato > 0 && this.novoDiscador.SetorId && this.novoDiscador.CanalId && this.novoDiscador.Status != null && this.novoDiscador.Contatos && this.novoDiscador.Contatos.length > 0;
			}
		},
		methods: {
			async consultarDiscadores() {
				this.discadores = null;
				let form = {
					DataInicio: this.dataInicio,
					DataFim: this.dataFim,
					Query: this.consulta,
					Status: this.status,
					SetorId: this.setor
				};

				return await axios.get("api/discador", {
					params: Object.assign({}, ...Object.keys(form).filter(key => form[key] != null).map(key => ({ [key]: form[key] })))
				}).then(response => {
					this.discadores = (response.data ?? []).map(item => ({ ...item, CommitedStatus: item.Status }));
				}).catch(() => {
					this.discadores = [];
				});
			},
			abrirModalNovoDiscador() {
				axios.get("api/discador/setores-validos").then(response => this.setoresValidos = response.data ?? []);
				this.$bvModal.show("criar-discador");
			},
			selecionarContatos() {
				this.$refs["modal-selecao-contatos"].selecionarContatosDiscagem().then(contatos => {
					this.novoDiscador.Contatos = contatos.rows.map(row => ({
						Telefone: row.Telefone,
						Metadados: row
					}));
				})
			},
			criarNovoDiscador() {
				this.loaders.criandoDiscador = true;
				axios.post("api/discador", this.novoDiscador).then(() => {
					this.consultarDiscadores().then(() => {
						this.loaders.criandoDiscador = false;
						this.$bvToast.toast(`O discador foi criado com sucesso${this.novoDiscador.Status == 0 ? ' e já está em execução' : ''}.`, {
							title: "Concluído",
							variant: "success",
							solid: true
						});
						this.$bvModal.hide("criar-discador");
					});
				}).catch(() => {
					this.loaders.criandoDiscador = false;
					this.$bvToast.toast(`Não foi possível criar o discador no momento.`, {
						title: "Algo deu errado...",
						variant: "danger",
						solid: true
					});
				});
			},
			async alterarStatusDiscador(discador, status = null) {
				let statusDescription = discador.StatusDescription;
				discador.StatusDescription = "Processando...";

				let novoStatus = status == null ? (discador.Status == 0 ? 1 : 0) : status;

				if (novoStatus == 2 && !await this.$bvModal.msgBoxConfirm(`Discadores arquivados não poderão ser ativados novamente, porém estarão disponíveis para consulta. Deseja continuar?`, {
					title: "Confirmação",
					size: "md",
					buttonSize: "md",
					okVariant: "danger",
				})) {
					discador.Status = discador.CommitedStatus;
					discador.StatusDescription = statusDescription;
					return;
				}

				let form = new FormData();
				form.append("status", novoStatus);

				axios.put(`api/discador/${discador.Id}/status`, form).then(() => {
					if ((status == 2 && this.status != 2) || (status != 2 && this.status != null)) {
						this.consultarDiscadores();
						return;
					}
					axios.get(`api/discador/${discador.Id}`).then(response => {
						Object.keys(response.data).forEach(key => {
							discador[key] = response.data[key];
							discador.CommitedStatus = novoStatus;
						});
					}).catch(() => {
						this.consultarDiscadores();
					});
				}).catch(() => {
					this.$bvToast.toast(`Não foi possível alterar o status do discador no momento.`, {
						title: "Algo deu errado...",
						variant: "danger",
						solid: true
					});
					discador.Status = discador.CommitedStatus;
					discador.StatusDescription = statusDescription;
				});
			},
			fecharModalNovoDiscador() {
				this.novoDiscador = {
					Identificacao: null,
					TentativasPorContato: 1,
					SetorId: null,
					CanalId: null,
					Status: 0,
					Contatos: null
				};
				this.canaisValidos = [];
			}
		},
		created() {
			axios.get("api/setor/lista-basica").then(response => this.setores = (response.data ?? []).map(item => ({
				Id: item.Id,
				Nome: item.Nome
			})));
			axios.get("api/discador/status").then(response => this.statusList = response.data ?? []);
			this.consultarDiscadores();
			this.moment = moment;
		}
	}
</script>

<style scoped>
	.discadores-container {
		width: calc(100% - 15px);
		height: calc(100vh - 125px);
		margin: 7.5px;
		padding: 10px;
		background-color: var(--cinza-3);
		cursor: default;
	}

		.discadores-container input {
			margin-right: 10px;
			padding: 7px;
		}

		.discadores-container select {
			margin-right: 10px;
			padding: 9px;
		}

	.discadores-list-contatiner {
		margin: 14px;
		background-color: #fff;
		padding: 0;
		height: calc(100vh - 195px);
		font-size: 13px;
		overflow-y: auto;
	}

		.discadores-list-contatiner table {
			color: var(--cinza-5);
			animation: ease-in slide-up .3s;
		}

	.data-field.insider-icon {
		max-width: 150px;
	}

		.data-field.insider-icon .spinner-border, .data-field.insider-icon .fas {
			display: block;
			width: 20px;
			height: 15px;
			margin: 0 7px;
		}
</style>